.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.neutral{
  --text-color: var(--black);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --bg-color: var(--taupe);
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-100) var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
  background: var(--bg-color);
  color: var(--text-color);
  padding: var(--padding-container);
}

.item{
  display: flex;
  flex-flow: row nowrap;
  gap: var(--spacing-16);
  align-items: center;
  font: var(--font-p1-desktop-default);
}

.icon{
  --size-icon:48px;
  height: var(--size-icon);
  width: var(--size-icon);
  flex: 0 0 auto;
}

.image{
  --size-icon:48px;
  position: relative;
  height: var(--size-icon);
  width: var(--size-icon);
  flex: 0 0 auto;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
  .item {
    font: var(--font-p1-mobile-default);
  }
}

@media (max-width: 480px) {
  .container{
    --padding-container: var(--spacing-40) var(--spacing-24);
  }
  .icon{
    --size-icon:32px;
  }
}